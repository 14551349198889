import { checkpaypassword,payment,calculate,orderCreate,balanceConfig } from "@/api/order/payment"
import { addressList,saveAddress,setDefault,deleteAddress,addressInfo,googlePlace,placeDetail } from "@/api/member/member"
import { showLoading, hideLoading } from '@/utils/loading'
import { getArea } from "@/api/address"
import { mapGetters } from "vuex"
import { couponList,receiveCoupon } from "@/api/coupon"
import { usediscountcode } from "@/api/discountcode"
import { logEvent } from "firebase/analytics";

export default {
	name: "payment",
	components: {},
	data: () => {
		var checkMobile = (rule, value, callback) => {
			if (value === "") {
				callback(new Error(vue.$lang('common.mobile')))
			} else if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(value)) {
				callback(new Error("手机号格式错误"))
			} else {
				callback()
			}
		}

		return {
			dialogVisible: false,
			checkLogin: false,
			memberAddress: [], //收货地址列表
			addressId: 0, //收货地址
			addressForm: {
				id: 0,
				email: "",
				first_name: "",
				last_name: "",
				mobile: "",
				telephone: "",
				country_id: "",
				province_id: "",
				city_id: "",
				city_name: "",
				post_code: "",
				district_id: "",
				community_id: "",
				address: "",
				full_address: "",
				is_default: "",
				longitude: "",
				latitude: "",
				country_name: "",
                province_name: ""
			},
			pickerValueArray: {},
			cityArr: {},
			districtArr: {},
			addressRules: {
				email: [
					{required: true,message:vue.$lang('email_trip'),trigger: "change"},
					{
						validator: function(rule, value, callback) {
						  if (/^([a-zA-Z0-9]+[_|\_|\.|\-]*)*([a-zA-Z0-9]?)+@([a-zA-Z0-9]+[_|\_|\.|\-]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/i.test(value) == false) {
							callback(new Error(vue.$lang("common.email_error")));
						  } else {
							callback();
						  }
						},
						trigger: "blur"
					}
				],
				first_name: [{required: true,message:vue.$lang('first_name_trip'),trigger: "change"}],
				last_name: [{required: true,message:vue.$lang('last_name_trip'),trigger: "change"}],
				mobile: [{required: true,message:vue.$lang('phone_trip'),trigger: "change"}],
				address: [{required: true,message: vue.$lang('address_s_trip'),trigger: "change"}],
				country_id: [{required: true,message: vue.$lang('select_country'),trigger: "change"}],
				province_id: [{required: true,message: vue.$lang('select_state'),trigger: "change"}],
				post_code: [{ required: true, message: vue.$lang('post_code_trip'), trigger: "change" }]
			},
			isSend: false,
			orderCreateData: {
				is_balance: 0,
				is_point: 1,
				pay_password: '',
				discount_code: '',
				// 发票
				is_invoice: 0, // 是否需要发票 0 无发票  1 有发票
				invoice_type: 1, // 发票类型  1 纸质 2 电子
				invoice_title_type: 1, // 抬头类型  1 个人 2 企业
				is_tax_invoice: 0, // 是否需要增值税专用发票  0 不需要 1 需要
				invoice_title: '', // 发票抬头
				taxpayer_number: '', // 纳税人识别号
				invoice_content: '', // 发票内容
				invoice_full_address: '', // 发票邮寄地址
				invoice_email: '', //发票邮箱
				member_address: {
					mobile: ''
				},
				is_open_card: 0,
				member_card_unit: ''
			},
			orderPaymentData: {
				shop_goods_list: {
					site_name: '',
					express_type: [],
					coupon_list: [],
					invoice: {
						invoice_content_array: []
					}
				},
				member_account: {
					balance: 0,
					is_pay_password: 0
				},
				delivery: {
					delivery_type: ''
				},
				member_address: {
					mobile: ''
				},
				local_config: {
					info: {
						start_time: 0,
						end_time: 0,
						time_week: []
					}
				}
			},
			dialogCoupon: false,
			siteCoupon: {
				site_id: 0,
				data: []
			},
			siteDelivery: {
				site_id: 0,
				data: []
			},
			dialogStore: false,
			promotionInfo: false,
			storeList: {},
			sitePromotion: [],
			isSub: false,
			dialogpay: false,
			password: "",
			fullscreenLoading: true,
			deliveryTime: false,
			timeTip: "选择配送时间",
			time: null,
			addressShow: false,
			couponRadio: 0,
			storeRadio: 0,
			dialogPlatcoupon: false,
			platformCouponRadio: 0,
			countrys: [],//国家信息
			provinces: [], //省份信息
			province: [], //省份信息
			isProvinceList: false,
			defaultRegions: [],
			google_tag: [],
			placeList: [],
			placekeyword: '',
			couponList: [],
			// 门店信息
			storeInfo: {
				storeList: [], //门店列表
				currStore: {} //当前选择门店
			},
			// 自提地址
			member_address: {
				mobile: ''
			},
			// 当前时间
			timeInfo: {
				week: 0,
				start_time: 0,
				end_time: 0,
				showTime: false,
				showTimeBar: false
			},
			balance_show : 1,
			addresNextType : true,
			allow_buy: true
		}
	},
	computed: {
		...mapGetters(["orderCreateGoodsData", "defaultGoodsImage", "city", "siteInfo", "member"]),
		
		dateRange () {
			const { defaultRegions, countrys } = this
			return {
				defaultRegions,
				countrys
			}
		},
		logined: function() {
			return this.member !== undefined && this.member !== "" && this.member !== {}
		}
	},
	watch: {
		dateRange (val) {
			// console.log('>>> dateRange');
			// console.log(val);
			if(val.defaultRegions.length > 0 && val.countrys.length>0){
				this.handleRegions()
			}
		}
	},
	mounted() {},
	created() {
		if (this.member !== undefined && this.member !== "" && this.member !== {}) {
			this.checkLogin = true;
		} 
		this.getMemberAddress()
		this.getOrderPaymentData(0) 
    	this.getCanReceiveCouponQuery()
		this.getBalanceConfig()
	},

	filters: {
		/**
		 * 金额格式化输出
		 * @param {Object} money
		 */
		moneyFormat(money) {
			return parseFloat(money).toFixed(2)
		},
		/**
		 * 店铺优惠摘取
		 */
		promotion(data) {
			let promotion = ""
			if (data) {
				Object.keys(data).forEach(key => {
					promotion += data[key].content + "　"
				})
			}
			return promotion
		}
	},
	methods: {
		querySearchPlace(queryString, cb) {
			if (this.placekeyword == queryString) {
				cb(this.placeList);
			} else {
				this.placekeyword = queryString
				clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					googlePlace({
						keyword: queryString
					}).then(res => {
						if(res.data && res.data.length > 0) {
							var results = res.data
							this.placeList = results
							cb(results);
						} else {
							var results = this.placeList
							cb(results);
						}
					}).catch(err => {})
				}, 2000 * Math.random());
			}
		},
		handleSelectPlace(item) {
			if (item.place_id) {
				placeDetail({
					place_id: item.place_id
				}).then(res => {
					var results = res.data
					
					if (results.address) {
						this.addressForm.address = results.address;
					}

					if(results.city) {
						this.addressForm.city_name = results.city;
					}
					if(results.country_id) {
						this.addressForm.country_id = results.country_id;
						this.addressForm.country_name = results.country_name;
						this.isProvinceList = true;
						this.province = this.provinces[results.country_id]
					}

					if(results.province_id) {
						this.addressForm.province_id = results.province_id;
						this.addressForm.province_name = results.province_name;
					}

					if (results.postal_code) {
						this.addressForm.post_code = results.postal_code;
					}
				}).catch(err => {})

			} else {
				if (item.address) {
					this.addressForm.address = item.address;
				}
				if(item.city) {
					this.addressForm.city_name = item.city;
				}
				if(item.country) {
					this.addressForm.country_id = item.country;
					this.addressForm.country_name = item.country_name;
					this.isProvinceList = true;
					this.province = this.provinces[item.country]
				}
	
				if(item.province) {
					this.addressForm.province_id = item.province;
					this.addressForm.province_name = item.province_name;
				}
			}
		},
		//改变国家
		changeCountry(id){
			//查询国家是否有城市信息
			if(this.provinces[this.addressForm.country_id]){
				this.province = this.provinces[this.addressForm.country_id]
				//显示城市下拉框
				this.isProvinceList = true
				this.addressForm.province_id = ''
			}else{
				// 如果城市列表为空，就隐藏城市下拉框
				this.isProvinceList = false
				this.addressForm.province_id = ''
			}
			
			let obj = this.countrys.find(item => item.id === id )
			this.addressForm.country_name = obj.name
			console.log(obj);
		},

		//改变省
		changeNewProvice(){
			let obj = this.province.find(item =>{
				return item.id === this.addressForm.province_id
			})
			this.addressForm.province_name = obj.name
		},
		//获取余额支付配置
		getBalanceConfig() {
			balanceConfig().then(res => {
				const { code,message,data } = res
				if (code >= 0) {
					this.balance_show = data.balance_show;
				}
			}).catch(err => {
				const { code,message,data } = err
				// this.$message.error(message)
			})
		},
		//获取收货地址
		getMemberAddress() {
			addressList({page_size: 0}).then(res => {
				const { code, message, data } = res
				if (data && data.list) {
					let that = this
					this.memberAddress = data.list
					data.list.forEach(function(e) {
						if (e.is_default == 1) {
							that.addressId = e.id
						}
					})
				}
			}).catch(err => {
				const {code,message,data} = err
				this.$message.error(message)
			})
		},

		//设置会员收货地址
		setMemberAddress(params) {
			this.addressId = params
			if (!this.checkLogin) {
				if(localStorage.getItem('website_uuid')) {
					var website_uuid = localStorage.getItem('website_uuid')
				}else{
					var website_uuid = '';
				}
			}

			setDefault({id: params, website_uuid: website_uuid}).then(res => {
				const {code,message,data} = res
				this.orderCalculate()
				this.googletagorder(1)
			}).catch(err => {
				const {code,message,data} = err
				this.$message.error(message)
			})
		},

		//删除会员收货地址
		deleteMemberAddress(params) {
			deleteAddress({id: params}).then(res => {
				const {code,message,data} = res
				if (data) {
					this.$message({message: message,type: "success"})
					this.getMemberAddress()
				} else {
					this.$message({message: message,type: "warning"})
				}
			}).catch(err => {
				this.$message.error(err.message)
			})
		},

		//打开添加收货地址弹出层
		addAddressShow() {
			if (this.logined) {
				this.checkLogin = true;
			} 
			// if(!this.userLogin()) return true;
			
			this.dialogVisible = true
			this.addressForm.id = 0
			this.addressForm.email = ""
			this.addressForm.first_name = ""
			this.addressForm.last_name = ""
			this.addressForm.mobile = ""
			this.addressForm.telephone = ""
			this.addressForm.country_id = ""
			this.addressForm.province_id = ""
			this.addressForm.city_id = ""
			this.addressForm.city_name = ""
			this.addressForm.district_id = ""
			this.addressForm.community_id = ""
			this.addressForm.address = ""
			this.addressForm.full_address = ""
			this.addressForm.is_default = ""
			this.addressForm.longitude = ""
			this.addressForm.latitude = ""
			this.addressForm.country_name = ""
            this.addressForm.province_name = ""
			// this.$nextTick(() => {
			// 	this.$refs.form.resetFields();
			// });
			this.cityArr = {}
			this.districtArr = {}
			this.getAddress(0)
		},

		//获取地址
		getAddress(type) {
			let pid = 0
			let that = this
			switch (type) {
				case 0:
					//加载省
					pid = 0
					break
				case 1:
					//加载市
					pid = this.addressForm.province_id
					that.cityArr = {}
					that.districtArr = {}
					this.addressForm.city_id = ""
					this.addressForm.district_id = ""
					break
				case 2:
					//加载区县
					pid = this.addressForm.city_id
					that.districtArr = {}
					this.addressForm.district_id = ""
					break
			}

			getArea({pid: pid}).then(res => {
				const {code, message, data} = res
				if (data) {
					switch (type) {
						case 0:
							that.pickerValueArray = data
							break
						case 1:
							//加载市
							that.cityArr = data
							break
						case 2:
							//加载区县
							that.districtArr = data
							break
					}
				}

				this.countrys = data['1']
				this.provinces = data['2']
				this.addressForm.country_id = this.countrys[0].id
				this.addressForm.country_name = this.countrys[0].name;
				this.isProvinceList = true;
				this.province = this.provinces[this.countrys[0].id]
			}).catch(err => {
				const {code, message, data} = err
				this.$message.error(message)
			})
		},

		//编辑地址 初始化
		initAddress(type) {
			let pid = 0
			let that = this
			switch (type) {
				case 0:
					//加载省
					pid = 0
					break
				case 1:
					//加载市
					pid = this.addressForm.province_id
					that.cityArr = {}
					that.districtArr = {}
					break
				case 2:
					//加载区县
					pid = this.addressForm.city_id
					that.districtArr = {}
					break
			}

			getArea({pid: pid}).then(res => {
				const { code, message, data } = res
				if (data) {
					switch (type) {
						case 0:
							that.pickerValueArray = data
							break
						case 1:
							//加载市
							that.cityArr = data
							break
						case 2:
							//加载区县
							that.districtArr = data
							break
					}

					this.countrys = data['1']
					this.provinces = data['2']
					this.addressForm.country_id = this.countrys[0].id
					this.addressForm.country_name = this.countrys[0].name;
					this.isProvinceList = true;
					this.province = this.provinces[this.countrys[0].id]
				}
			}).catch(err => {
				const { code, message, data } = err
				this.$message.error(message)
			})
		},

		//新增/编辑收货地址
		addmemberAddress(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.isSend) {
						return false
					}

					if (!this.addressForm.id) {
						let data = {
							first_name: this.addressForm.first_name,
							last_name: this.addressForm.last_name,
							mobile: this.addressForm.mobile,
							telephone: this.addressForm.telephone,
							city_name: this.addressForm.city_name,
							country_id: this.addressForm.country_id,
							province_id: this.addressForm.province_id,
							city_id: this.addressForm.city_id,
							post_code: this.addressForm.post_code,
							district_id: this.addressForm.district_id,
							community_id: "",
							address: this.addressForm.address,
							full_address: this.addressForm.full_address,
							longitude: this.addressForm.longitude,
							latitude: this.addressForm.latitude,
							is_default: this.addressForm.is_default,
							country_name: this.addressForm.country_name,
                            province_name: this.addressForm.province_name,
						}

						if (this.checkLogin) {
							data.url = 'add'
						} else {
							if(localStorage.getItem('website_uuid')) {
								data.website_uuid = localStorage.getItem('website_uuid')
							}else{
								data.website_uuid = '';
							}
							data.email = this.addressForm.email
							data.url = 'anonymou_add'
							if(localStorage.getItem('suid')) {
								data.suid = localStorage.getItem('suid')
							}
						}

						if (!data.country_id || data.country_id <= 0) {
							this.$message({message: "请选择国家/地区", type: "warning"})
							return false
						}
						
						this.isSend = true
						showLoading();
						
						if (data.url == 'anonymou_add') {
							logEvent(this.$analytics, 'register', {'app_name': 'pc'})
						}
						
						saveAddress(data).then(res => {
							hideLoading();
							const {code, message, data} = res
							if (data) {
								this.setMemberAddress(data)

								this.$message({message: message, type: "success"})
								this.dialogVisible = false
								this.getMemberAddress()
								this.getOrderPaymentData(1)
							} else {
								this.$message({message: message, type: "warning"})
							}
							this.isSend = false
						}).catch(err => {
							hideLoading();
							const {code, message, data} = err
							this.$message.error(message)
						})
					} else {
						let data = this.addressForm
						if (!data.country_id) {
							this.$message({message: "请选择国家/地区", type: "warning"})
							return false
						}
						this.isSend = true
						this.setMemberAddress(data.id);
						if (!this.checkLogin) {
							if(localStorage.getItem('website_uuid')) {
								data.website_uuid = localStorage.getItem('website_uuid')
							}else{
								data.website_uuid = '';
							}
							data.url = "anonymou_edit";
						} else {
							data.url = "edit";
						}
						
						saveAddress(data).then(res => {
							const {code, message, data} = res
							if (data) {
								this.$message({message: message, type: "success"})
								this.dialogVisible = false
								this.getMemberAddress()
								this.getOrderPaymentData(1)
							} else {
								this.$message({message: message, type: "warning"})
							}
							this.isSend = false
						}).catch(err => {
							const {code, message, data} = err
							this.$message.error(message)
						})
					}
				} else {
					return false
				}
			})
		},

		//编辑收货地址
		editAddress(id) {
			var params = {id: id}
			if (!this.checkLogin) {
				if(localStorage.getItem('website_uuid')) {
					params.website_uuid = localStorage.getItem('website_uuid')
				}else{
					params.website_uuid = '';
				}
			}

			addressInfo(params).then(res => {
				const { code,message,data } = res
				this.addressForm = {
					id: data.id,
					first_name: data.first_name,
					last_name: data.last_name,
					mobile: data.mobile,
					telephone: data.telephone,
					country_id: data.country_id,
					province_id: data.province_id,
					city_id: "",
					city_name: data.city_name,
					post_code: data.post_code,
					district_id: "",
					community_id: "",
					address: data.address,
					full_address: data.full_address,
					is_default: data.is_default,
					longitude: data.longitude,
					latitude: data.latitude,
					country_name: data.country_name,
					province_name: data.province_name,
				}
				if (!this.checkLogin) {
					this.$set(this.addressForm, 'email', data.email);
					//this.addressForm.email = data.email
				}
				this.initAddress(0)
				this.initAddress(1)
				this.addressForm.city_id = data.city_id
				this.initAddress(2)
				this.addressForm.district_id = data.district_id
				this.defaultRegions = [data.country_id, data.province_id]
				this.dialogVisible = true
			}).catch(err => {
				const { code,message,data } = err
				this.$message.error(message)
			})
		},
		
		//渲染地址栏
		handleRegions(){
			if(this.countrys.length>0){
				this.addressForm.country_id = this.defaultRegions[0]
				if(this.provinces[this.addressForm.country_id]){
					this.isProvinceList = true
					this.province = this.provinces[this.addressForm.country_id]
					this.addressForm.province_id = this.defaultRegions[1]
				}else{
					this.isProvinceList = false
				}
			}
		},

		/**
		 * 获取订单初始化数据
		 */
		getOrderPaymentData(next) {
			this.orderCreateData = this.orderCreateGoodsData
			
			if (!this.orderCreateData) {
				this.$message({
					message: "未获取到创建订单所需数据！", //提示的信息
					type: "warning",
					offset: 225,
					duration: 3000,
					onClose: () => {
						this.$router.go(-1)
						return false
					}
				})
				return
			}
			this.orderCreateData.web_city = this.city ? this.city.id : 0
			payment(this.orderCreateData).then(res => {
				const {code,message,data} = res

					if (code >= 0) {
						this.orderPaymentData = data

						this.google_tag = data.google_tag;
						this.googletagorder(next)

						this.handlePaymentData()
					} else {
						this.$message({
							message: "未获取到创建订单所需数据！", //提示的信息
							type: "warning",
							offset: 225,
							duration: 3000,
							onClose: () => {
								this.$router.go(-1)
								return false
							}
						})
						return
					}
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					// this.$message.error(message)
				})
		},
		/**
		 * 处理结算订单数据
		 */
		handlePaymentData() {
			this.orderCreateData.delivery = {}
			this.orderCreateData.coupon = {}
			this.orderCreateData.buyer_message = ""
			this.orderCreateData.is_balance = 0
			this.orderCreateData.pay_password = ""
			this.orderCreateData.is_point = 1;
			
			this.orderCreateData.is_invoice = 0; // 是否需要发票 0 无发票  1 有发票
			this.orderCreateData.invoice_type = 1; // 发票类型  1 纸质 2 电子
			this.orderCreateData.invoice_title_type = 1; // 发票抬头类型 1 个人 2企业
			this.orderCreateData.is_tax_invoice = 0; // 是否需要增值税专用发票  0 不需要 1 需要
			this.orderCreateData.invoice_title = '';
			
			var data = this.orderPaymentData
			if (data.recommend_member_card) {
				this.orderCreateData.member_card_unit = Object.keys(data.recommend_member_card.charge_rule)[0];
			}
			// 店铺的配送
			if (data.shop_goods_list.express_type.length > 1) {
				console.log(data.shop_goods_list.express_type, '配送方式')
				this.orderCreateData.delivery_type = 'express'
				this.orderCreateData.delivery_type_name = '物流配送'
			}
			// 店铺优惠券
			if (data.shop_goods_list.coupon_list != undefined && data.shop_goods_list.coupon_list[0] != undefined) {
				var coupon_list = data.shop_goods_list.coupon_list;
				this.orderCreateData.coupon.coupon_id = coupon_list[0].coupon_id;
				this.orderCreateData.coupon.coupon_money = coupon_list[0].money;
			}

			// 满减送
			if (data.shop_goods_list.promotion != undefined && data.shop_goods_list.promotion.manjian != undefined) {
				this.manjian = data.shop_goods_list.promotion.manjian;
			}

			if (this.orderPaymentData.is_virtual) this.orderCreateData.member_address = {
				mobile: ''
			};

			if (this.orderPaymentData.shop_goods_list.invoice) {
				var invoice_content_array = this.orderPaymentData.shop_goods_list.invoice.invoice_content_array;
				if (invoice_content_array.length) this.orderCreateData.invoice_content = invoice_content_array[0];
			}

			if (this.orderPaymentData.is_virtual == 0) {

				// 是否显示时间选择
				if (this.orderPaymentData.shop_goods_list.local_config.info && this.orderPaymentData
					.shop_goods_list.local_config.info.time_is_open == 1) {
					this.timeInfo.showTimeBar = true;
					// 当日是否支持配送
					if (this.orderPaymentData.shop_goods_list.local_config.info.time_week.length == 0 ||
						this.orderPaymentData.shop_goods_list.local_config.info.time_week.length == 7 ||
						this.orderPaymentData.shop_goods_list.local_config.info.time_week.indexOf(this.timeInfo.week) > -1) {
						this.canLocalDelicery = true;
					} else {
						this.canLocalDelicery = false;
					}

					if (this.orderPaymentData.shop_goods_list.local_config.info.time_type == 0) {
						this.deliveryWeek = "全天";
					} else if (this.orderPaymentData.shop_goods_list.local_config.info.time_week.length > 0) {

						if (this.orderPaymentData.shop_goods_list.local_config.info.time_week.length == 7) {
							this.deliveryWeek = "全天";
						} else {
							this.timeInfo.showTime = true;
							// 判断配送时间是连续还是间隔
							var timeWeek = this.orderPaymentData.shop_goods_list.local_config.info.time_week;
							var is_interval = false; // 是否间隔
							for (var i = 0; i < timeWeek.length; i++) {
								if ((i + 1) < timeWeek.length) {
									var difference = timeWeek[i + 1] - timeWeek[i];
									if (difference > 1) {
										is_interval = true;
										break;
									}
								}
							}

							var weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
							if (is_interval) {
								var temp = [];
								for (var i = 0; i < timeWeek.length; i++) {
									temp.push(weeks[timeWeek[i]]);
								}
								this.deliveryWeek = temp.join("、");
							} else {
								this.deliveryWeek = weeks[timeWeek[0]] + "至" + weeks[timeWeek[timeWeek.length - 1]];
							}
						}
					} else {
						this.deliveryWeek = "店铺未设置配送时间";
					}

					// 将timepicker选中当前时间
					var h = new Date().getHours().toString();
					var m = new Date().getMinutes().toString();
					if (h.length == 1) {
						h = '0' + h;
					}
					if (m.length == 1) {
						m = '0' + m;
					}

					this.orderCreateData.buyer_ask_delivery_time = h + ':' + m;

					// picker组件时间起始
					let start_time = this.orderPaymentData.shop_goods_list.local_config.info.start_time;
					this.timeInfo.start_time = this.getTimeStr(start_time);

					let end_time = this.orderPaymentData.shop_goods_list.local_config.info.end_time;
					this.timeInfo.end_time = this.getTimeStr(end_time);

					var current_time = new Date(this.$util.timeStampTurnTime(this.orderPaymentData.timestamp));
					var hour = current_time.getHours();
					var minute = current_time.getMinutes();

					var start_hour = parseInt(this.timeInfo.start_time.split(":")[0]);
					var start_minute = parseInt(this.timeInfo.start_time.split(":")[1]);

					var end_hour = parseInt(this.timeInfo.end_time.split(":")[0]);
					var end_minute = parseInt(this.timeInfo.end_time.split(":")[1]);

					// 检测当天是否能够配送，然后判断送达时间。不在配送时间当日不能下单，例：配送时间是周一到周五，那么周末不能下单，周一到周五可以下单
					if (this.canLocalDelicery) {

						// 判断是否全天
						if (!(start_hour == end_hour && start_minute == end_minute)) {

							// 当前时间早于配送时间，送达时间：开始时间~结束时间
							if ((hour < start_hour) || (hour == start_hour && minute < start_minute)) {
								this.orderCreateData.buyer_ask_delivery_time = (start_hour.toString().length == 1 ? "0" + start_hour :
										start_hour) + ':' +
									(start_minute.toString().length == 1 ? "0" + start_minute : start_minute);
							}

							// if (((hour > start_hour && hour < end_hour) || (hour == start_hour && minute > start_minute) || (hour ==
							// 		start_hour && minute >= start_minute && hour < end_hour))) {
							// }

							// 当前时间晚于配送时间，送达时间隐藏，不能下单
							if ((hour > end_hour) || (hour == end_hour && minute > end_minute)) {
								this.canLocalDelicery = false;
							}
						}

					}

				} else {
					this.deliveryWeek = "店铺未开启配送时间";
				}

			}

			Object.assign(this.orderPaymentData, this.orderCreateData);
			this.orderPaymentData.shop_goods_list.goods_list.forEach((v) => {
				if (v.sku_spec_format) {
					console.log(v)
					v.sku_spec_format = JSON.parse(v.sku_spec_format);
				} else {
					v.sku_spec_format = [];
				}
			});
			this.orderCalculate()
		},
		googletagorder(next) {
			if(next == 0) {
				// google
				logEvent(this.$analytics, 'begin_checkout', {
					coupon: '',
					currency: 'AUD',
					items: this.google_tag,
					value: this.orderPaymentData.order_money
				});
				//trackInitiateCheckout({"currency": 'AUD',"value": this.orderPaymentData.order_money});
			}
			
			if(next == 1) {
				// google
				logEvent(this.$analytics, 'add_shipping_info', {
					coupon: '',
					currency: 'AUD',
					items: this.google_tag,
					value: this.orderPaymentData.order_money,
					shipping_tier: this.getOrderPaymentData.delivery_money
				});
			}
		},
		// 转化时间字符串
		getTimeStr(val) {
			var h = parseInt(val / 3600).toString();
			var m = parseInt((val % 3600) / 60).toString();
			if (m.length == 1) {
				m = '0' + m;
			}
			if (h.length == 1) {
				h = '0' + h;
			}
			return h + ':' + m;
		},
		clickType(type){
			this.orderCreateData.invoice_type = type;
			this.orderCalculate();
			this.$forceUpdate();
		},
		clickTitleType(type) {
			this.orderCreateData.invoice_title_type = type;
			this.orderCalculate();
			this.$forceUpdate();
		},
		// 选择发票内容
		changeInvoiceContent(invoice_content) {
			this.orderCreateData.invoice_content = invoice_content;
			this.$forceUpdate();
		},
		
		// 切换发票开关
		changeIsInvoice() {
			if (this.orderCreateData.is_invoice == 0) {
				this.orderCreateData.is_invoice = 1;
			} else {
				this.orderCreateData.is_invoice = 0;
			}
			this.orderCalculate();
			this.$forceUpdate();
		},
		
		// 发票验证
		invoiceVerify() {
			if (!this.orderCreateData.invoice_title) {
				this.$message({
					message: "请填写发票抬头",
					type: "warning"
				})
				return false;
			}
			if (!this.orderCreateData.taxpayer_number && this.orderCreateData.invoice_title_type == 2) {
				this.$message({
					message: "请填写纳税人识别号",
					type: "warning"
				})
				return false;
			}
			if (this.orderCreateData.invoice_type == 1 && !this.orderCreateData.invoice_full_address) {
				this.$message({
					message: "请填写发票邮寄地址",
					type: "warning"
				})
				return false;
			}
			if (this.orderCreateData.invoice_type == 2 && !this.orderCreateData.invoice_email) {
				this.$message({
					message: "请填写邮箱",
					type: "warning"
				})
				return false;
			}
			if (this.orderCreateData.invoice_type == 2) {
				var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
				if (!reg.test(this.orderCreateData.invoice_email)) {
					this.$message({
						message: "请填写正确的邮箱",
						type: "warning"
					})
					return false;
				}
			}
			if (!this.orderCreateData.invoice_content) {
				this.$message({
					message: "请选择发票内容",
					type: "warning"
				})
				return false;
			}
			return true;
		},
		
		/**
		 * 订单计算
		 */
		orderCalculate() {
			this.fullscreenLoading = true
			var data = this.$util.deepClone(this.orderCreateData)
			data.delivery = JSON.stringify(data.delivery)
			data.coupon = JSON.stringify(data.coupon)
			if (this.orderPaymentData.delivery_type == 'store') {
				console.log('store')
				data.member_address = JSON.stringify(this.member_address);
			} else {
				data.member_address = JSON.stringify(data.member_address);
			}
			calculate(data).then(res => {
				const {code,message,data} = res
				if (code >= 0) {
					this.orderPaymentData.member_address = res.data.member_address;
					this.orderPaymentData.delivery_money = res.data.delivery_money;
					this.orderPaymentData.coupon_money = res.data.coupon_money;
					this.orderPaymentData.invoice_money = res.data.invoice_money;
					this.orderPaymentData.invoice_delivery_money = res.data.shop_goods_list.invoice_delivery_money;
					this.orderPaymentData.promotion_money = res.data.promotion_money;
					this.orderPaymentData.order_money = res.data.order_money;
					this.orderPaymentData.balance_money = res.data.balance_money;
					this.orderPaymentData.pay_money = res.data.pay_money;
					this.orderPaymentData.goods_money = res.data.goods_money;
					this.orderPaymentData.point_money = res.data.point_money;
					this.orderPaymentData.discountcode_money = res.data.discountcode_money
					this.orderPaymentData.member_card_money = res.data.member_card_money;
					this.orderPaymentData.shop_goods_list.promotion = res.data.shop_goods_list.promotion;
					this.orderPaymentData.shop_goods_list.coupon_list = res.data.shop_goods_list.coupon_list;
					this.orderPaymentData.shop_goods_list.goods_list = res.data.shop_goods_list.goods_list;
					this.orderPaymentData.shop_goods_list.max_usable_point = res.data.shop_goods_list.max_usable_point;
					this.orderPaymentData.shop_goods_list.goods_list.forEach((v) => {

						if (v.sku_spec_format) {
							console.log(v)
							v.sku_spec_format = JSON.parse(v.sku_spec_format);
						} else {
							v.sku_spec_format = [];
						}
					});

				} else {
					this.$message({
						message: message, //提示的信息
						type: "warning",
						offset: 225,
						duration: 3000,
						onClose: () => {
							this.$router.go(-1)
							return false
						}
					})
					return
				}
				this.fullscreenLoading = false
			}).catch(err => {
				const {code,message,data} = err
				this.$message.error(message)
				this.fullscreenLoading = false
			})
		},


		/**
		 * 选择配送方式
		 */
		selectDeliveryType(data, siteId, deliveryData) {
			this.tempData = {
				delivery: this.$util.deepClone(this.orderPaymentData.delivery)
			}

			this.siteDelivery.site_id = siteId
			this.siteDelivery.data = deliveryData

			this.orderCreateData.delivery_type = data.name
			this.orderCreateData.delivery_type_name = data.title
			if (data.name == "store") {
				// 如果是门店配送
				// let row = '';
				let that = this;
				data.store_list.forEach(function(e, i) {
					data.store_list[i]["store_address"] = e.full_address + e.address
				})

				this.dialogStore = true
				this.storeList = data.store_list
				this.orderPaymentData.delivery.delivery_type = 'store'
				this.orderPaymentData.delivery.delivery_type_name = '门店自提'
				Object.assign(this.orderPaymentData, this.orderCreateData)
				if (this.orderPaymentData.delivery.store_id) {
					this.orderCalculate()
				}else{
					this.orderPaymentData.delivery.store_id = 0;
				}

			} else if (data.name == "local") {
				this.deliveryTime = true
				this.time = this.orderCreateData.delivery[siteId].buyer_ask_delivery_time;
			} else {
				console.log(this.orderPaymentData, 'orderPaymentData')
				this.orderPaymentData.delivery.delivery_type = 'express'
				this.orderPaymentData.delivery.delivery_type_name = '物流配送'
				Object.assign(this.orderPaymentData, this.orderCreateData)
				console.log(this.orderPaymentData, 'this.orderPaymentData')
				this.orderCalculate()
			}
			this.$forceUpdate()
		},

		/**
		 * 设置选择自提点
		 * @param {Object} item
		 */
		setStore(row) {
			this.$refs.singleTable.setCurrentRow(row)
		},

		/**
		 * 选择自提点
		 * @param {Object} item
		 */
		selectStore(item) {
			if (!item) return;
			let store_id = item.store_id
			this.dialogStore = false
			this.orderCreateData.delivery.store_id = store_id
			this.orderCreateData.delivery.delivery_type = this.orderCreateData.delivery_type
			this.orderCreateData.delivery.store_name = item.store_name
			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.storeRadio = item
			this.orderCalculate()
			this.$forceUpdate()
		},

		/**
		 * 显示优惠券信息
		 * @param {Object} siteId
		 * @param {Object} couponData
		 */
		openPlatformCoupon() {
			let row = 0
			let that = this;
			this.dialogPlatcoupon = true
			this.orderPaymentData.shop_goods_list.coupon_list.forEach(function(e, i) {
				if (e.coupon_id == that.orderPaymentData.coupon.coupon_id) {
					row = e
					console.log(e)
				}
			})

			setTimeout(function() {
				that.setPlatformCurrent(row)
				that.platformCouponRadio = row;
			}, 50)
		},

		/**
		 * 取消选择优惠券
		 * @param {Object} item
		 */
		setPlatformCurrent(row) {
			this.$refs.platformCouponTable.setCurrentRow(row)
			if (row == undefined) {
				console.log('取消')
				this.orderCalculate()
			}
		},
		/**
		 * 确认选择优惠券
		 * @param {Object} item
		 */
		savePlatformCoupon() {
			this.dialogPlatcoupon = false
			console.log('确认')
			this.orderCalculate()
		},

		/**
		 * 选择优惠券
		 * @param {Object} item
		 */
		selectPlatformCoupon(item) {
			if (this.orderCreateData.coupon.coupon_id != item.coupon_id) {
				this.orderCreateData.coupon.coupon_id = item.coupon_id;
				this.orderCreateData.coupon.coupon_money = item.money;
				this.platformCouponRadio = item;
			} else {
				this.orderCreateData.coupon.coupon_id = 0;
				this.orderCreateData.coupon.coupon_money = '0.00';
				this.platformCouponRadio = '';
			}
			Object.assign(this.orderPaymentData, this.orderCreateData);
			console.log(this.orderPaymentData.coupon, 'this.orderCreateData.coupon')
			this.$forceUpdate()

		},

		/**
		 * 显示店铺优惠券信息
		 * @param {Object} siteId
		 * @param {Object} couponData
		 */
		// openSiteCoupon(siteId, couponData) {
		// 	this.tempData = {
		// 		coupon: this.$util.deepClone(this.orderPaymentData.coupon)
		// 	}
		// 	let row = 0
		// 	let that = this
		// 	this.siteCoupon.site_id = siteId
		// 	couponData.forEach(function(e, i) {
		// 		if (e.at_least > 0) {
		// 			couponData[i]["use"] = "满" + e.at_least + "可用"
		// 		} else {
		// 			couponData[i]["use"] = "任意金额可用"
		// 		}
		// 		couponData[i]["time"] = timeStampTurnTime(e.end_time)

		// 		if (e.coupon_id == that.orderCreateData.coupon[that.siteCoupon.site_id].coupon_id) {
		// 			row = e
		// 		}
		// 	})
		// 	this.siteCoupon.data = couponData
		// 	this.dialogCoupon = true

		// 	setTimeout(function() {
		// 		that.setCurrent(row)
		// 		that.couponRadio = row;
		// 	}, 50)
		// },
		/**
		 * 选择优惠券
		 * @param {Object} item
		 */
		selectCoupon(item) {
			if (!item) {
				this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_id = 0
				this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_money = "0.00"
				this.couponRadio = '';
			} else {
				if (this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_id != item.coupon_id) {
					this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_id = item.coupon_id
					this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_money = item.money
					this.couponRadio = item;
				} else {
					this.couponRadio = '';
					this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_id = 0
					this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_money = "0.00"
				}
			}
			Object.assign(this.orderPaymentData, this.orderCreateData)

			this.$forceUpdate()
		},

		/**
		 * 取消选择优惠券
		 * @param {Object} item
		 */
		setCurrent(row) {
			this.$refs.couponTable.setCurrentRow(row)
			if (row == undefined) {
				this.orderCalculate()
			}
		},
		/**
		 * 确认选择优惠券
		 * @param {Object} item
		 */
		saveCoupon() {
			this.dialogCoupon = false
			this.orderCalculate()
		},

		/**
		 * 显示店铺优惠信息
		 * @param {Object} data
		 */
		openSitePromotion(data) {
			this.sitePromotion = data
			if (this.promotionInfo) {
				this.promotionInfo = false
			} else {
				this.promotionInfo = true
			}
		},

		/**
		 * 是否使用余额
		 */
		useBalance(type) {
			if (this.orderCreateData.is_balance) this.orderCreateData.is_balance = 0
			else this.orderCreateData.is_balance = 1
			this.orderCalculate()
			this.$forceUpdate()
		},

		// 使用积分
		usePoint(type) {
			if (this.orderCreateData.is_point) this.orderCreateData.is_point = 0;
			else this.orderCreateData.is_point = 1;
			this.orderCalculate();
			this.$forceUpdate();
		},

		useDiscountCode() {
			if (this.orderCreateData['discount_code']) {
				var data = this.$util.deepClone(this.orderCreateData)
				var loading = this.$loading({
					lock: true,
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)"
				})
				usediscountcode(data).then(res => {
					loading.close()
					this.orderCalculate()
					this.$forceUpdate()
				}).catch(err => {
					this.orderCreateData.discount_code = ''
					loading.close()
					const { code,message,data } = err
					this.$message.error(message)
				})
			}
		},

		orderCreate() {
			if (this.verify()) {
				if (this.isSub) return
				if(this.orderCreateData.is_invoice == 1){
					if(this.invoiceVerify() === false) return;
				}
		
				this.isSub = true

				var loading = this.$loading({
					lock: true,
					text: vue.$lang('order_submit'),
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)"
				})
				var data = this.$util.deepClone(this.orderCreateData);
				data.delivery = JSON.stringify(data.delivery);
				data.coupon = JSON.stringify(data.coupon);
				if (this.orderCreateData.delivery.delivery_type == 'store') {
					data.member_address = JSON.stringify(this.member_address);
				} else {
					data.member_address = JSON.stringify(data.member_address);
				}
				orderCreate(data).then(res => {
					const {code,message,data} = res
					loading.close()
					if (code >= 0) {
						this.$store.dispatch("order/removeOrderCreateData", "")
						if (this.orderPaymentData.pay_money == 0) {
							this.$router.push({path: "/result",query: {code: data}})
						} else {
							this.$router.push({path: "/pay",query: {code: data}})
						}
					} else {
						this.$message({message: message,type: "warning"})
					}
				}).catch(err => {
					loading.close()
					this.isSub = false
					const {code,message,data} = err
					this.$message.error(message)
				})
			}
		},
		/**
		 * 订单验证
		 */
		verify() {
			if (this.orderPaymentData.is_virtual == 0) {
				if (!this.orderPaymentData.member_address) {
					this.$message({message: vue.$lang("select_shipping_address"), type: "warning"})
					return false
				}
			}
			return true
		},
		/**
		 * 支付密码输入
		 */
		input() {
			if (this.password.length == 6) {
				var loading = this.$loading({
					lock: true,
					text: "支付中",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)"
				})

				checkpaypassword({pay_password: this.password}).then(res => {
					const {code,message,data} = res
					loading.close()
					if (code >= 0) {
						this.orderCreateData.pay_password = this.password
						this.orderCreate()
						this.dialogpay = false
					} else {
						this.$message({message: message,type: "warning"})
					}
				}).catch(err => {
					loading.close()
					const { code,message,data } = err
					this.$message.error(message)
				})
			}
		},

		textarea() {
			this.$forceUpdate()
		},
		bindTimeChange(time) {
			this.time = time
		},
		setDeliveryTime(site_id) {
			this.deliveryTime = false
			this.orderCreateData.delivery[site_id].buyer_ask_delivery_time = this.time;
		},
		imageError(index) {
			this.orderPaymentData.shop_goods_list.goods_list[index].sku_image = this.defaultGoodsImage
		},
		setPayPassword() {
			this.$router.pushToTab("/member/security");
		},

		userLogin() {
			if (!this.logined) {
				this.dialogLogin = true
				this.loginForm.email = ""
				this.loginForm.dynacode = ""
				this.loginForm.key = ""
				this.dynacodeData.seconds = 120
				return false;
			}
			return true;
		},
		toLogin() {
			this.$router.push({path: '/login', query: {redirect: "/payment"}});
		},
		//获取优惠券列表
		getCanReceiveCouponQuery() {
			couponList({
				activeName: "first",
				site_id: this.siteId
			}).then(res => {
				let data = res.data
				this.couponList = []
				if (data != null) {
					for (let i = 0; i < data.list.length; i++) {
						if (i < 5) {
							this.couponList.push(data.list[i])
						}
					}
					this.couponList.forEach(v => {
						v.useState = 0
					})
				}
			}).catch(err => {})
		},
		/**
		 * 领取优惠券
		 */
		receiveCoupon(item, index) {
			if (this.isSub) return
			this.isSub = true

			var data = {
				site_id: item.site_id,
				activeName: "first",
				coupon_type_id: item.coupon_type_id
			}

			receiveCoupon(data).then(res => {
				var data = res.data
				let msg = res.message
				if (res.code == 0) {
					msg = "领取成功"
					this.$message({
						message: msg,
						type: "success"
					})
					this.getOrderPaymentData(3)
				} else {
					this.$message({
						message: msg,
						type: "warning"
					})
				}
				
				let list = this.couponList
				if (res.data.is_exist == 1) {
					for (let i = 0; i < list.length; i++) {
						if (list[i].coupon_type_id == item.coupon_type_id) {
							list[i].useState = 1
						}
					}
				} else {
					for (let i = 0; i < list.length; i++) {
						if (list[i].coupon_type_id == item.coupon_type_id) {
							list[i].useState = 2
						}
					}
				}

				this.isSub = false
				this.$forceUpdate()
			}).catch(err => {
				if (err.message == 'token不存在') {
					this.$router.push({path: '/login', query: {redirect: "/payment"}});
				} else {
					this.$message.error(err.message);
				}
				this.isSub = false
			})
		},
		/**
		 * 点击优惠券
		 */
		 couponTap(item, index) {
			if (item.useState == 0) this.receiveCoupon(item, index)
			else this.toGoodsList(item)
		},
		/**
		 * 去购买
		 */
		toGoodsList(item) {
			if (item.goods_type != 1) {
				this.$router.push({ path: '/list.html', query: { coupon_type: item.coupon_type_id } });
			} else {
				this.$router.push('/list.html');
			}
		},
	}
}
