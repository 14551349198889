import http from "../utils/http"

/**
 * 订单支付使用折扣码
 * @param {object} params
 */
export function usediscountcode(params) {
    return http({
        url: "/discountcode/api/discountcode/usediscountcode",
        data: params
    })
}
