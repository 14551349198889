<template>
	<div style="width:1200px; overflow: hidden;" v-loading="fullscreenLoading">
		<!--优惠券-->
		<div class="content-div" v-if="couponList.length > 0">
			<div class="coupon">
				<div class="coupon-title">
					<p class="coupon-font">{{$lang("common.coupons")}}</p>
					<p class="coupon-en">coupon</p>
					<p class="coupon-more" @click="$router.push('/coupon')">
						<span>{{$lang("common.more")}}</span>
						<i class="iconfont iconarrow-right"></i>
					</p>
				</div>
				<ul class="coupon-list">
					<li v-for="(item, index) in couponList" :key="index">
						<p class="coupon-price ns-text-color">
							{{ siteInfo.price_unit }}
							<span>{{ item.money }}</span>
						</p>
						<p class="coupon-term">
							<span>{{ item.coupon_name }}</span>
						</p>
						
						<p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
							<span v-if="item.useState == 0">{{$lang("common.get")}}</span>
							<span v-else>去使用</span>
							<i class="iconfont iconarrow-right"></i>
						</p>
					</li>
				</ul>
			</div>
		</div>
		
		<!--收货地址-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 0">
			<div class="block-text">{{$lang('shipping_address')}}</div>

			<div clsas="address-list">
				<div class="address-item" @click="addAddressShow" v-if="checkLogin || (!checkLogin && memberAddress.length == 0)">
					<div class="add-address">
						<i class="el-icon-circle-plus-outline"></i>
						{{$lang('add_shipping_address')}}
					</div>
				</div>

				<div class="address-item" v-for="(item, key) in memberAddress" :key="item.id" :class="addressId == item.id ? 'active' : ''"  v-if="key < 3 || (addressShow && key >= 3)">
					<div class="address-info">
						<div class="options">
							<div @click="editAddress(item.id)">{{$lang('common.edit')}}</div>
							<template v-if="item.is_default == 0">
								<el-popconfirm :title="$lang('delete_address')" @onConfirm="deleteMemberAddress(item.id)">
									<div slot="reference">{{$lang('common.delete')}}</div>
								</el-popconfirm>
							</template>
						</div>
						<div class="address-name">{{ item.first_name + '&nbsp;' + item.last_name }}</div>
						<div class="address-mobile" @click="setMemberAddress(item.id)">{{ item.mobile }}</div>
						<div class="address-contact" @click="setMemberAddress(item.id)">{{ item.email }}</div>
						<div class="address-desc" @click="setMemberAddress(item.id)">{{ item.address }} {{ item.full_address }}</div>
					</div>
				</div>

				<div v-if="memberAddress.length > 3 && !addressShow" @click="addressShow = true" class="el-button--text address-open">
					<i class="el-icon-arrow-down"></i>
					{{$lang('more_address')}}
				</div>

				<div class="clear"></div>
			</div>
		</div>

		<!--购买虚拟类商品需填写您的手机号-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 1">
			<div class="block-text">购买虚拟类商品需填写您的手机号，以方便商家与您联系</div>

			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item label="手机号码">
					<el-input placeholder="请输入您的手机号码" maxlength="11" v-model="orderCreateData.member_address.mobile"></el-input>
				</el-form-item>
			</el-form>
		</div>

		<!--收货地址添加-->
		<el-dialog :title="addressForm.id == 0 ? $lang('add_shipping_address') : $lang('edit_shipping_address')" :visible.sync="dialogVisible" width="32%">
			<el-form ref="form" :rules="addressRules" :model="addressForm" label-width="110px">
				<el-form-item :label="$lang('email')" prop="email" v-if="!checkLogin">
					<el-col :span="12">
						<el-input v-model="addressForm.email" :placeholder="$lang('email_trip')"></el-input>
					</el-col>
					<el-col :span="1">&nbsp;</el-col>
					<el-col :span="11">
						<span>{{$lang('existing_account')}} <span style="color:#EA4D19;cursor: pointer;" @click="toLogin">{{$lang('login')}}</span></span>
					</el-col>
				</el-form-item>
				<el-form-item :label="$lang('consignee')" prop="first_name">
					<el-col :span="11">
						<el-form-item prop="first_name">
							<el-input v-model="addressForm.first_name" :placeholder="$lang('first_name_trip')"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="1">&nbsp;</el-col>
					<el-col :span="12">
						<el-form-item prop="last_name">
							<el-input v-model="addressForm.last_name" :placeholder="$lang('last_name_trip')"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item :label="$lang('address')" prop="address">
					<el-autocomplete
						class="ns-len-input"
						v-model="addressForm.address"
						:fetch-suggestions="querySearchPlace"
						:placeholder="$lang('address_trip')"
						@select="handleSelectPlace"
						autocomplete="off"
					>
					</el-autocomplete>
				</el-form-item>

				<el-form-item :label="$lang('suburb')">
					<el-input v-model="addressForm.city_name" :placeholder="$lang('suburb_trip')"></el-input>
				</el-form-item>

				<el-form-item class="area" :label="$lang('country_state')" prop="country_id">
					<el-row :gutter="10">
						<el-col :span="10">
							<el-select ref="country" v-model="addressForm.country_id" @change="changeCountry" :placeholder="$lang('select_country')">
								<el-option v-for="item in countrys" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="13" v-if="isProvinceList">
							<el-form-item prop="province_id">
								<el-select ref="province" prop="province" v-model="addressForm.province_id" @change="changeNewProvice" :placeholder="$lang('select_state')">
									<el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form-item>
				
				<el-form-item :label="$lang('post_code')" prop="post_code">
					<el-input v-model="addressForm.post_code" :placeholder="$lang('post_code_trip')"></el-input>
				</el-form-item>

				<el-form-item :label="$lang('phone')" prop="mobile">
					<el-input v-model="addressForm.mobile" maxlength="11" :placeholder="$lang('phone_trip')"></el-input>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{$lang('common.cancel')}}</el-button>
				<el-button type="primary" @click="addmemberAddress('form')">{{$lang('common.confirm')}}</el-button>
			</span>
		</el-dialog>

		<!-- 配送方式 -->
		<div class="item-block padd-bom-20" v-if="orderPaymentData.shop_goods_list.express_type.length > 1">
			<div class="block-text">配送方式
				<span class="distribution" v-if="orderPaymentData.delivery_type=='store'">{{orderCreateData.delivery.store_name}}</span>
			</div>
			<div class="pay-type-item" v-for="(deliveryItem, deliveryIndex) in orderPaymentData.shop_goods_list.express_type"
			 :key="deliveryIndex" @click="selectDeliveryType(deliveryItem, orderPaymentData.site_id, orderPaymentData.shop_goods_list.express_type)"
			 :class="orderPaymentData.delivery_type == deliveryItem.name ? 'active' : ''" v-if="deliveryItem.name != 'local'">
				{{ deliveryItem.title }}
			</div>

		</div>
		
		<div class="item-block" v-if="orderPaymentData.shop_goods_list.invoice">
			<div class="block-text">发票信息</div>
			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_invoice == 0 ? 'active' : ''" @click="changeIsInvoice">无需发票</div>
				<div class="pay-type-item" :class="orderCreateData.is_invoice == 1 ? 'active' : ''" @click="changeIsInvoice">需要发票</div>
				<div class="clear"></div>
			</div>
			<div class="invoice-information" v-if="orderCreateData.is_invoice == 1">
				<div class='invoice-title'>
					<div class="invoice-type-box invoice-title-box">
						<span class="invoice-name">发票类型：</span>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_type == 1 ? 'active' : '' " @click="clickType(1)"></i>
							<!-- <i v-if="orderCreateData.invoice_type == 1"></i>
							<input type="radio" name="invoice_type" value="1" @click="clickType(1)" :checked="orderCreateData.invoice_type == 1 ? 'checked' : '' "/> -->
							纸质
						</label>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_type == 2 ? 'active' : '' "  @click="clickType(2)"></i>
							<!-- <i v-if="orderCreateData.invoice_type == 2"></i>
							<input type="radio" name="invoice_type" value="2" @click="clickType(2)" :checked="orderCreateData.invoice_type == 2 ? 'checked' : '' "/> -->
							电子
						</label>
					</div>
					<div class="invoice-type-box invoice-title-box">
						<span class="invoice-name">抬头类型：</span>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_title_type == 1 ? 'active' : '' "  @click="clickTitleType(1)"></i>
							<!-- <i v-if="orderCreateData.invoice_title_type == 1"></i>
							<input type="radio" name="invoice_rise_type" value="1" @click="clickTitleType(1)" :checked="orderCreateData.invoice_title_type == 1 ? 'checked' : '' "/> -->
							个人
						</label>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_title_type == 2 ? 'active' : '' "  @click="clickTitleType(2)"></i>
							<!-- <i v-if="orderCreateData.invoice_title_type == 2"></i>
							<input type="radio" name="invoice_rise_type" value="2" @click="clickTitleType(2)" :checked="orderCreateData.invoice_title_type == 2 ? 'checked' : '' "/> -->
							企业
						</label>
					</div>
				</div>
				<div class="invoice-type-box">
					<span class="invoice-name">发票信息：</span>
					<div class="invoice-box-form">
						<input type="text" placeholder="请填写抬头名称" v-model.trim="orderCreateData.invoice_title"/>
						<input type="text" placeholder="请填写纳税人识别号" v-model.trim="orderCreateData.taxpayer_number" v-if="orderCreateData.invoice_title_type == 2"/>
						<input type="text" placeholder="请填写邮寄地址" v-model.trim="orderCreateData.invoice_full_address" v-if="orderCreateData.invoice_type == 1"/>
						<input type="text" placeholder="请填写邮箱" v-model.trim="orderCreateData.invoice_email" v-if="orderCreateData.invoice_type == 2"/>
					</div>
				</div>
				<div class="invoice-condition">
					<span class="invoice-name">发票内容：</span>
					<div class="invoice-box-form">
						<span class="option-item" 
						:key="index"	
						v-for="(item, index) in orderPaymentData.shop_goods_list.invoice.invoice_content_array"
						@click="changeInvoiceContent(item)"
						:class="{ 'color-base-bg active': item == orderCreateData.invoice_content }"
						>
						{{item}}
						</span>
					</div>
				</div>
			</div>
		
		</div>
		
		<!--商品信息-->
		<div class="item-block">
			<div class="goods-list">
				<table>
					<tr>
						<td width="50%">{{$lang('goods_item')}}</td>
						<td width="12.5%">{{$lang('price')}}</td>
						<td width="12.5%">{{$lang('quantity')}}</td>
						<td width="12.5%">{{$lang('subtotal')}}</td>
					</tr>
				</table>
			</div>
		</div>
		<div>
			<div class="item-block">
				<div class="goods-list">
					<table>
						<tr v-for="(goodsItem, goodsIndex) in orderPaymentData.shop_goods_list.goods_list" :key="goodsIndex">
							<td width="50%">
								<div v-if="goodsItem.is_present">
									<div class="goods-info-left">
										<img class="goods-img" :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
									</div>
									<div class="goods-info-right">
										<div class="present-name">
											<label class="present-label" >{{$lang('gift')}}</label>
											{{ goodsItem.sku_name }}
										</div>
									</div>
								</div>
								<div v-else class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/product-' + goodsItem.goods_id + '.html' }" target="_blank">
											<img class="goods-img" :src="$img(goodsItem.sku_image)" @error="imageError(goodsIndex)" />
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/product-' + goodsItem.goods_id + '.html' }" target="_blank">
											<div class="goods-name">{{ goodsItem.sku_name }}</div>
										</router-link>
										<!-- 规格 -->
										<div class="goods-spec" v-if="goodsItem.sku_spec_format">
											<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">{{ x.spec_value_name }} {{ i < goodsItem.sku_spec_format.length - 1 ? '; ' : '' }}</span>
										</div>
									</div>
								</div>
							</td>
							<td width="12.5%" class="goods-price">{{ siteInfo.price_unit + goodsItem.price }}</td>
							<td width="12.5%" class="goods-num">{{ goodsItem.num }}</td>
							<td width="12.5%" class="goods-money">
								{{ siteInfo.price_unit }}{{(goodsItem.price * goodsItem.num).toFixed(2)}}
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<!--优惠券-->
		<div class="item-block" v-if="orderPaymentData.shop_goods_list.coupon_list.length">
			<div class="block-text">{{$lang('coupon')}}</div>
			<div class="order-cell platform-coupon">
				<div v-if="orderPaymentData.coupon_money!=0">
					<span class="ns-text-color" @click="openPlatformCoupon">{{$lang('use_coupon')}}</span>
					<span class="ns-text-color" @click="openPlatformCoupon">
						<span class="inline">{{ siteInfo.price_unit }}</span>
						{{ orderPaymentData.coupon_money.toFixed(2) }}
					</span>
				</div>
				<div v-else>
					<div class="box ns-text-color" @click="openPlatformCoupon">{{$lang('no_use_coupon')}}</div>
				</div>
			</div>
		</div>

		<!-- 买家留言 -->
		<div class="item-block padd-bom-10">
			<div class="block-text">{{$lang('buyer_message')}}:</div>
			<el-input rows="3" type="textarea" :placeholder="$lang('message')" v-model="orderCreateData.buyer_message" class="buyer-message"
			 @input="textarea" maxlength="140" show-word-limit resize="none"></el-input>
		</div>

		<!--使用余额-->
		<div class="item-block" v-if="orderPaymentData.member_account.balance_total > 0 && balance_show == 1">
			<div class="block-text">{{$lang('use_balance')}}</div>
			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_balance ? '' : 'active'" @click="useBalance(0)">{{$lang('use_no_balance')}}</div>
				<div class="pay-type-item" :class="orderCreateData.is_balance ? 'active' : ''" @click="useBalance(1)">{{$lang('use_balance')}}</div>
				<div class="clear"></div>
			</div>
		</div>

		<!--使用积分抵扣-->
		<div class="item-block" v-if="orderPaymentData.shop_goods_list.max_usable_point > 0">
			<div class="block-text">{{$lang('use_point')}}<div class="ns-text-color">（{{orderPaymentData.member_account.point}} 积分）</div></div>

			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_point ? '' : 'active'" @click="usePoint(0)">{{$lang('use_no_point')}}</div>
				<div class="pay-type-item" :class="orderCreateData.is_point ? 'active' : ''" @click="usePoint(1)">{{$lang('point_deduction')}}</div>
				<div class="clear"></div>
			</div>
		</div>

		<!--平台折扣码-->
		<div class="discountcode-block">
			<div class="discountcode-box">
				<el-input type="text" class="discount_code" :placeholder="$lang('discountcode_trip')" v-model="orderCreateData.discount_code"></el-input>
				<el-button type="primary" @click="useDiscountCode()">应用</el-button>
			</div>
		</div>
		
		<!-- 总计 -->
		<div class="item-block">
			<div class="order-statistics">
				<table>
					<tr>
						<td align="right">{{$lang('goods_amount')}}：</td>
						<td align="left">{{ siteInfo.price_unit }}{{ orderPaymentData.goods_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.is_virtual == 0">
						<td align="right">{{$lang('freight')}}：</td>
						<td align="left">{{ siteInfo.price_unit }}{{ orderPaymentData.delivery_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_money > 0">
						<td align="right">税费：</td>
						<td align="left">{{ siteInfo.price_unit }}{{ orderPaymentData.invoice_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_delivery_money > 0">
						<td align="right">发票邮寄费：</td>
						<td align="left">￥{{ orderPaymentData.invoice_delivery_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.promotion_money > 0">
						<td align="right">{{$lang('discount')}}：</td>
						<td align="left">{{ siteInfo.price_unit + orderPaymentData.promotion_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.point_money > 0">
						<td align="right">>{{$lang('point_deduction')}}：</td>
						<td align="left">{{ siteInfo.price_unit + orderPaymentData.point_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.discountcode_money > 0">
						<td align="right">{{$lang('discountcode_deduction')}}：</td>
						<td align="left">{{ orderPaymentData.discountcode_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.coupon_money > 0">
						<td align="right">{{$lang('coupon')}}：</td>
						<td align="left">{{ siteInfo.price_unit + orderPaymentData.coupon_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.balance_money > 0">
						<td align="right">{{$lang('use_balance')}}：</td>
						<td align="left">{{ siteInfo.price_unit + orderPaymentData.balance_money | moneyFormat }}</td>
					</tr>
				</table>
			</div>
			<div class="clear"></div>
		</div>

		<!--结算-->
		<div class="item-block">
			<div class="order-submit">
				<div class="order-money">
					{{$lang('a_payable_amount')}} {{ orderPaymentData.goods_num }} {{$lang('payable_amount')}}：
					<div class="ns-text-color">{{ siteInfo.price_unit }}{{ orderPaymentData.pay_money | moneyFormat }}</div>
				</div>
				<el-button type="primary" class="el-button--primary" @click="orderCreate">{{$lang('settlement')}}</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<!--选择平台优惠券弹框-->
		<el-dialog :title="$lang('select_coupon')" :visible.sync="dialogPlatcoupon" width="50%" @close="savePlatformCoupon()">
			<el-table ref="platformCouponTable" :data="orderPaymentData.shop_goods_list.coupon_list" highlight-current-row
			 @row-click="selectPlatformCoupon" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="platformCouponRadio" :label="scope.row"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="platformcoupon_name" :label="$lang('name')" width="200">
					<template slot-scope="scope">
						<span>{{ scope.row.coupon_name }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="money" :label="$lang('discount')"></el-table-column>
				<el-table-column :label="$lang('use')">
					<template slot-scope="scope">
						<span class="ns-text-color-gray ns-font-size-sm" v-if="scope.row.at_least > 0">{{$lang('full')}}{{ scope.row.at_least }} {{$lang('available')}}</span>
						<span class="ns-text-color-gray ns-font-size-sm" v-else>{{$lang('all_available')}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$lang('deadline')">
					<template slot-scope="scope">
						<span>{{ $util.timeStampTurnTime(scope.row.end_time) }}</span>
					</template>
				</el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogPlatcoupon = false">{{$lang('cancel')}}</el-button>
				<el-button @click="dialogPlatcoupon = false" type="primary">{{$lang('confirm_select')}}</el-button>
			</div>
		</el-dialog>

		<!--配送方式  门店 -->
		<el-dialog title="选择门店" :visible.sync="dialogStore" width="50%">
			<el-table ref="singleTable" :data="storeList" highlight-current-row @row-click="selectStore" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<el-radio v-model="storeRadio" :label="scope.row"><i></i></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" label="名称" width="160"></el-table-column>
				<el-table-column prop="store_address" label="地址"></el-table-column>
				<el-table-column prop="open_date" label="营业时间"></el-table-column>
			</el-table>
		</el-dialog>

		<!-- 支付密码 -->
		<el-dialog title="使用余额" :visible.sync="dialogpay" width="350px">
			<template v-if="orderPaymentData.member_account.is_pay_password == 0">
				<p>为了您的账户安全,请您先设置的支付密码</p>
				<p>可到"会员中心","账号安全","支付密码"中设置</p>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="dialogpay = false">暂不设置</el-button>
					<el-button size="small" type="primary" @click="setPayPassword">立即设置</el-button>
				</span>
			</template>
			<el-form v-else status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="支付密码" class="pay-password-item">
					<!--添加一个不可见的input,欺骗浏览器自动填充-->
					<el-input type="password" class="pay-password hide-password" :maxlength="6"></el-input>
					<el-input type="password" class="pay-password" :maxlength="6" v-model="password" @input="input"></el-input>
				</el-form-item>
				<p class="ns-text-color forget-password" @click="setPayPassword">忘记密码</p>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import PicZoom from 'vue-piczoom';
	import detail from './payment.js';
	export default {
		name: 'payment',
		components: {
			PicZoom
		},
		mixins: [detail]
	};
</script>
<style scoped>
.discountcode-block /deep/ .el-input__inner {
	border-radius: 4px 0px 0px 4px;
}

.discountcode-block /deep/ .el-button {
	border-radius: 0px 4px 4px 0px;
}
</style>
<style lang="scss" scoped>
	@import './payment.scss';
</style>
